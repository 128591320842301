html {
  height: 100%;
  width: 100%;
}

body {
  font-family: 'SF Pro Display', Arial;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  background-color: rgba(123, 123, 178, 1);
}

.inline {
  display: block;
  padding-top: 16px;
  padding-bottom: 36px;
}

.content {
  display: block;
  position: relative;
  cursor: default;
  margin: 0 auto;
  background-color: rgba(255, 255, 255, 1);
  border-top-left-radius: 16.00px;
  border-top-right-radius: 16.00px;
  border-bottom-left-radius: 16.00px;
  border-bottom-right-radius: 16.00px;
  box-shadow: none;
  width: 80.00%;
}

@media(max-width: 414px) {
  .content {
    margin-top: 0px;
    padding-top: 20px;
    border-radius: 0px;
    width: 100%;
  }
}

.content .back {
  font-size: 20px;
  font-weight: 700;
  font-style: normal;
  font-family: 'SF Pro Display', Arial;
  color: #606060;
  padding-top: 16px;
  padding-left: 16px;
}

.filling {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  text-align: center;
}

.content .title {
  position: relative;
  box-sizing: content-box;
  -ms-transform-origin: center;
  -webkit-transform-origin: center;
  transform-origin: center;
  opacity: 1;
  cursor: default;
  background-color: rgba(255, 255, 255, 0);
  text-align: left;
  line-height: 40.00px;
  letter-spacing: 0.00px;
  color: rgba(54, 54, 54, 1);
  -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
  font-size: 30.00px;
  font-weight: 700;
  font-style: normal;
  font-family: 'SF Pro Display', Arial;
  text-decoration: none;
  box-shadow: none;
  overflow-x: unset;
  overflow-y: unset;
  height: 62px;
  padding-top: 48px;
  text-align: center;
}

@media(max-width: 414px) {
  .content .title {
    font-size: 24px;
    line-height: 50px;
    padding-top: 48px;
    width: auto;
    margin: auto;
    text-align: center;
    height: 50px;
  }
}

.content .text {
  display: inline-block;
  margin: auto;
  margin: 0;
  margin-top: 48px;
  transform: translateY(-16%);
}

@media(max-width: 768px) {
  .content .text {
    margin: auto;
    padding-top: 36px;
  }
}

.content .text .instruction {
  display: inline-block;
  position: relative;
  box-sizing: content-box;
  -ms-transform-origin: center;
  -webkit-transform-origin: center;
  transform-origin: center;
  opacity: 1;
  cursor: default;
  background-color: rgba(255, 255, 255, 0);
  text-align: center;
  letter-spacing: 0.00px;
  color: #687078;
  -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
  font-size: 24px;
  font-weight: 400;
  font-style: normal;
  font-family: 'SF Pro Display', Arial;
  text-decoration: none;
  box-shadow: none;
  overflow-x: unset;
  overflow-y: unset;
  max-height: 151.00px;
  left: 0.00px;
  padding-top: 16px;
  padding-bottom: 10px;
}

.instruction-link {
  text-decoration: underline;
  text-underline-offset: 10px;
  text-decoration-thickness: 1px;
  color: #687078;
  cursor: pointer;
}

@media(max-width: 414px) {
  .content .text .instruction {
    margin-left: 48px;
    text-align: center;
    margin: auto;
  }
}

.btn {
  position: relative;
  box-sizing: content-box;
  -ms-transform: rotate(0.00deg);
  -webkit-transform: rotate(0.00deg);
  transform: rotate(0.00deg);
  -ms-transform-origin: center;
  -webkit-transform-origin: center;
  transform-origin: center;
  opacity: 1;
  cursor: default;
  background-color: rgba(87, 80, 170, 1);
  border-bottom-color: rgba(246, 212, 117, 1);
  border-bottom-style: none;
  border-bottom-width: 4.00px;
  border-left-color: rgba(246, 212, 117, 1);
  border-left-style: none;
  border-left-width: 4.00px;
  border-right-color: rgba(246, 212, 117, 1);
  border-right-style: none;
  border-right-width: 4.00px;
  border-top-color: rgba(246, 212, 117, 1);
  border-top-style: none;
  border-top-width: 4.00px;
  border-top-left-radius: 8.00px;
  border-top-right-radius: 8.00px;
  border-bottom-left-radius: 8.00px;
  border-bottom-right-radius: 8.00px;
  box-shadow: none;
  width: 100%;
  height: 69.00px;
}

.btn:hover {
  background-color: #443C9D;
}

.btn_choose {
  position: relative;
  box-sizing: content-box;
  -ms-transform: rotate(0.00deg);
  -webkit-transform: rotate(0.00deg);
  transform: rotate(0.00deg);
  -ms-transform-origin: center;
  -webkit-transform-origin: center;
  transform-origin: center;
  opacity: 1;
  cursor: pointer;
  background-color: #3C9BE9;
  border-top-left-radius: 8.00px;
  border-top-right-radius: 8.00px;
  border-bottom-left-radius: 8.00px;
  border-bottom-right-radius: 8.00px;
  box-shadow: none;
  width: 174px;
  height: 60px;
  left: 0.00px;
  top: 0.00px;
  font-size: 24px;
  margin-top: 16px;
  display: inline-block;
}

.btn_choose .choose {
  background-color: rgba(255, 255, 255, 0);
  text-align: center;
  line-height: 40.00px;
  letter-spacing: 0.00px;
  color: rgba(255, 255, 255, 1);
  font-size: 20.00px;
  font-weight: 700;
  font-style: normal;
  font-family: 'SF Pro Display', Arial;
  padding-top: 8px;
  text-align: center;
}

.inline p {
  display: inline-block;
  color: #363636;
  font-weight: 700;
  font-style: normal;
  font-family: 'SF Pro Display', Arial;
}

@media(max-width: 414px) {
  .content .text .btn {
    margin: auto;
    width: 300px;
    height: 69px;
    margin-top: 40px;
    text-align: center;
  }
  .btn_choose {
    margin: auto;
    top: 16px;
  }
}

.getMyFreeReport {
  position: relative;
  box-sizing: content-box;
  -ms-transform: rotate(0.00deg);
  -webkit-transform: rotate(0.00deg);
  transform: rotate(0.00deg);
  -ms-transform-origin: center;
  -webkit-transform-origin: center;
  transform-origin: center;
  opacity: 1;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0);
  text-align: center;
  line-height: 40.00px;
  letter-spacing: 0.00px;
  color: rgba(255, 255, 255, 1);
  -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
  font-size: 24.00px;
  font-weight: 700;
  font-style: normal;
  font-family: 'SF Pro Display', Arial;
  text-decoration: none;
  box-shadow: none;
  overflow-x: unset;
  overflow-y: unset;
  white-space: pre;
  max-width: 285.00px;
  min-width: 200px;
  max-height: 29.00px;
  top: 14.50px;
}

@media(max-width: 414px) {
  .getMyFreeReport {
    font-size: 24px;
    text-align: center;
  }
}

.submitbtn p {
  font-style: normal;
  font-family: 'SF Pro Display', Arial;
  font-size: 16px;
  color: #D64747;
  margin: 4px;
}

.submitbtn {
  display: inline-block;
  text-align: center;
}