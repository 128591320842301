html {
  height: 100%;
  width: 100%;
}

body {
  font-family: 'SF Pro Display', Arial;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
}

.db {
  display: block;
}

.dib {
  /* display: inline-block; */
  float: left;
  height: 100%
}

.right {
  float: right;
}

.middle {
  margin-left: 15vw;
}

header {
  background-color: #fff;
}

button .material-icons {
  display: inline-block;
  width: 20px;
  height: 20px;
  white-space: nowrap;
  letter-spacing: -1em;
  text-indent: -1em;
}

section {
  background-color: #FCFCFC;
  padding: 24px;
  padding: 36px;
}

section hr {
  border-top: 1px solid #cfcfcf;
}

section .label {
  color: #cfcfcf;
}

.section_width {
  width: 65%;
}

.legend span {
  vertical-align: middle;
  margin-left: 24px;
  padding-bottom: 30px;
}

.card {
  border: 1px solid #E0E0E0;
  border-radius: 8px;
  padding-left: 24px;
  padding-right: 24px;
  width: 25vw;
  background-color: #fff;
  vertical-align: top;
  height: 330px;
  overflow: scroll;
  margin-top: 16px;
}

.card h1 {
  color: #363636;
}

.card .text_green {
  color: #19D83E;
}

.card_hr {
  margin-left: 24px;
}

.card_hf {
  display: inline;
  /* margin-right: 24px; */
}

.card_aside {
  margin-left: 24px;
  margin-bottom: 24px;
  margin-right: 24px;
}

.aside_title {
  margin-left: 24px;
}

article {
  background-color: #fff;
  height: 100%;
}

aside {
  background-color: #FCFCFC;
  vertical-align: top;
  margin-top: 16px;
  margin-left: 24px;
  padding: 0px;
}

@media (max-width:480px) {
  .card {
    width: auto!important;
  }
  svg {
    text-align: center;
    margin: 0 auto;
    width: 80vw;
  }
  .card_hr {
    margin: 0 auto;
  }
  .card_hf {
    margin-bottom: 36px;
  }
  .card_aside {
    margin-bottom: 36px;
  }
  .section_width {
    width: 100%;
  }
  section {
    background-color: #FCFCFC;
    padding: 24px;
    margin: 0px;
  }
  .aside_title {
    padding-top: 48px;
    text-align: center;
  }
  h2 {
    font-size: 24px;
    text-align: center;
  }
  article {
    float: none;
    width: 100%;
    height: auto;
  }
  aside {
    float: none;
    width: auto;
    height: 70vh;
    overflow-x: hidden;
    padding-left: 16px;
  }
}

.dib table {
  border-collapse: collapse;
  border-radius: 8px;
  border: 1px solid #cfcfcf;
  overflow: hidden;
  width: 100%;
  margin-bottom: 24px;
}

.table-responsive {
  overflow: auto;
}

th, td {
  padding: 16px;
  /* background: #fdfdfd; */
  background: transparent;
  border-bottom: 1px solid #cfcfcf;
}

.text_right {
  text-align: right;
}

.text_center {
  text-align: center;
}

.text_left {
  text-align: left;
}

.example img {
  max-width: 95%;
  height: auto;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  right: 0;
  background-color: #fff;
  min-width: 160px;
  border-radius: 8px;
  z-index: 1;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.dropdown-content span {
  color: #363636;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  cursor: pointer;
}

.dropdown-content span:hover {
  background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: #fff;
  border-radius: 4px;
}

.dropdown p {
  font-size: 14px;
  color: #888888;
  padding-left: 12px;
}

.dropdown hr {
  border-top: 1px solid #cfcfcf;
}

button {
  color: #fff;
  height: 40px;
  background-color: #5750AA;
  vertical-align: middle;
  outline-style: none;
  border-width: 0px;
  border-radius: 4px;
  margin-top: 16px;
  margin-right: 16px;
  padding-left: 8px;
}

button .btn_text {
  margin-bottom: 5px;
  vertical-align: super;
}

.btn_secondary {
  background-color: #E5E3FF;
  color: #5750AA;
  margin: 0 auto;
  padding-top: 4px;
  height: 36px;
  float: right;
}

.btn_secondary .material-icons {
  font-size: 14px;
  vertical-align: text-top;
  color: #5750AA;
}

.collapsible {
  background-color: #FCFCFC;
  vertical-align: top;
  margin-top: 16px;
  font-weight: bold;
}

.active, .collapsible:hover {
  background-color: #FCFCFC;
}

.collapse_content {
  display: none;
  overflow: hidden;
  background-color: #FCFCFC;
  border-radius: 16px;
}

.collapse .material-icons {
  vertical-align: sub;
}

.ecgplot {
  overflow: visible;
  display: block;
  width: 100%;
  height: auto;
}

.plotdata {
  padding-top: 5vh;
}

/* The Modal (background) */

.modal {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Sit on top */
  padding-top: 100px;
  /* Location of the box */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
}

/* Modal Content */

.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  border-radius: 16px;
}

/* The Close Button */

.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover, .close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.watermark {
  /* background-image: url(../assets/motus-logo-black.png); */
  display: block;
  position: fixed;
  width: 120vw;
  top: 100px;
  left: 70px;
  bottom: 300px;
  z-index: -1;
  opacity: 1;
  display: none;
}

.watermark img {
  width: 80vw;
  top: 300px;
}

/* Button style */

.title_button {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.export_button {
  text-align: center;
  color: rgba(255, 255, 255, 1);
  -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
  font-size: 24.00px;
  font-weight: 700;
  font-style: normal;
  font-family: 'SF Pro Display', Arial;
  text-decoration: none;
  box-shadow: none;
  overflow-x: unset;
  overflow-y: unset;
  white-space: pre;
  max-width: 200px;
  min-width: 100px;
  max-height: 50px;
  right: 5%;
}

/* Extracted from w3.css */

.w3-button {
  border: none;
  display: inline-block;
  padding: 8px 16px 8px 16px;
  vertical-align: middle;
  overflow: hidden;
  text-decoration: none;
  color: inherit;
  background-color: inherit;
  text-align: center;
  cursor: pointer;
  white-space: nowrap;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}

.w3-disabled, .w3-btn:disabled, .w3-button:disabled {
  cursor: not-allowed;
  opacity: 0.3
}

.w3-disabled *, :disabled * {
  pointer-events: none
}

.w3-btn.w3-disabled:hover, .w3-btn:disabled:hover {
  box-shadow: none
}

.print-only {
  display: none;
}

.print-style-in-jpg {
  display: block;
  width: 65vw;
  padding: 8px 8% 8px 0;
}

#hrv-detail-table {
  overflow: scroll;
  text-align: center;
}

@media print {
  @page {
    size: A4 portrait;
    margin: 0.3cm;
    orphans: 2;
    widows: 2;
  }
  body {
    print-color-adjust: exact;
    -webkit-print-color-adjust: exact;
  }
  section {
    background-color: transparent;
  }
  article {
    background-color: transparent;
  }
  aside {
    background-color: transparent;
  }
  button {
    display: none;
  }
  .print-friendly {
    break-inside: avoid;
    page-break-inside: avoid;
    -webkit-break-inside: avoid;
  }
  .print-only {
    display: block;
    width: 100vw;
    padding: 8px 8% 8px 0;
  }
  .non-printable {
    display: none;
  }
  .card {
    height: auto;
    background-color: transparent;
    overflow: visible;
  }
  .w3-button {
    display: none;
  }
  .export_button {
    display: none;
  }
  .collapse_content {
    background-color: transparent;
    display: block!important;
  }
  .p_display_none {
    display: none;
  }
  .watermark {
    display: block;
  }
  .dropdown {
    display: none;
  }
  #ecg_image h2 {
    display: block;
  }
}