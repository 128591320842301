.loading-container {
    position: relative;
    width: 100%;
    height: 100%;
}

.loading-container img {
    position: absolute;
    display: block;
    margin: auto;
    text-align: center;
    top: 50vh;
    left: 0;
    right: 0;
    bottom: 0;
}

.loading {
    position: relative;
    top: 40vh;
    z-index: 1;
    text-align: center;
    font-size: 2rem;
    color: white;
    animation: 3s blink linear infinite;
}

.dot {
    height: 10px;
    width: 10px;
    margin: 10px 5px 0px 5px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
}

@keyframes blink {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.1;
    }
    100% {
        opacity: 1;
    }
}