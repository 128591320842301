.waveform-window {
    display: "flex";
    justify-content: "center";
    text-align: "center";
    float: left;
}

@media screen and (max-width: 600px) {
    .waveform-window {
        width: 100%;
    }
}

#go-to-window, #current-window {
    width: 25%;
}

#current-window {
    text-align: right;
}

#advance-window {
    width: 50%;
}