nav {
    background-color: rgba(123, 123, 178, 1);
    text-align: center;
    height: 72px;
    display: inline-flex;
    width: 100%;
}

@media print {
    nav {
        display: none;
    }
}

nav .motusLogo img {
    width: 150px;
}

@media (max-width:768px) {
    .motusLogo {
        width: 100%;
    }
    .section_width {
      width: 100%;
    }
    aside {
      margin-left: 0px;
    }
    .card {
      width: 78vw;
    }
}
.back {
    margin-left: 12px;
}

.back img {
    height: 40px;
    padding-top: 16px;
    cursor: pointer;
}

.motusLogo {
    text-align: center;
    justify-content: center;
    align-items: center;
    width: 100%;
}
